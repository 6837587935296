import React, { useState, useEffect } from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import {
  SetCurbsideOutfitInfoPayload,
  SetCurbsidePayload,
  SetDeliveryAddressPayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
} from '../../../../state/checkout/checkout.actions.types';
import styles from './DispatchSettings.scss';
import RestaurantTakeoutDetails from '../AddressInformation/RestaurantTakeoutDetails';
import CurbsidePickup from '../CurbsidePickup';
import { useBi, translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import dataHooks from '../../data-hooks';
import {
  Address,
  CurbsideInfo,
  DispatchInfo,
  DispatchType,
  getCachedTranslationFunction,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import ToggleSwitch from '../ToggleSwitch';
import Text from '../../core-components/Text';
import { getDispatchTimeSelectorDetails } from '../DispatchTimeSelector/DispatchTimeSelector.helper';

export type TimingOption = 'asap' | 'later';

export interface DispatchSettingsProps {
  onRequestClose: () => void;
  initialDispatchType: DispatchType;
  initialAddress?: Address;
  initialTimingOption?: TimingOption;
  restaurant: Restaurant;
  supportedDispatchTypes: Set<DispatchType>;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (dispatchType: SetDispatchTimePayload) => void;
  setDeliveryAddress: (address: SetDeliveryAddressPayload) => void;
  setCurbside: (isCurbside: SetCurbsidePayload) => void;
  setCurbsideOutfitInfo: (curbsideOutfitInfo: SetCurbsideOutfitInfoPayload) => void;
  initialDispatchTime?: number;
  initialCurbside?: boolean;
  curbsideInfo: CurbsideInfo | undefined;
  formattedAddressWithComment: string;
  isMobile: boolean;
  t: TranslationFunction;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
  isRTL?: boolean;
}

const DispatchSettings: React.FC<DispatchSettingsProps> = ({
  onRequestClose,
  initialDispatchType,
  initialAddress,
  initialCurbside,
  initialTimingOption,
  restaurant,
  supportedDispatchTypes,
  setDispatchType,
  setDispatchTime,
  setDeliveryAddress,
  setCurbside,
  setCurbsideOutfitInfo,
  initialDispatchTime,
  curbsideInfo,
  formattedAddressWithComment,
  t,
  isMobile,
  idealDeliveryArea,
  businessNotification,
  isRTL,
}) => {
  const biLogger = useBi();

  const [hasErrors, setHasErrors] = useState(false);
  const [dispatchType, setType] = useState(initialDispatchType);
  const [isCurbside, onCurbsideToggle] = useState(initialCurbside);
  const [localCurbsideOutfitInfo, setLocalCurbsideOutfitInfo] = useState('');
  const [timingOption, setTimingOption] = useState(initialTimingOption);
  const [selectedDateTime, setSelectedDateTime] = useState(initialDispatchTime || 0);

  const handleOkClick = () => {
    biLogger.dispatchSettingsUpdate({ dispatchTime: selectedDateTime, dispatchTimeOption: timingOption, dispatchType });
    setDispatchType({ dispatchType });

    if (dispatchType === 'delivery' && initialAddress) {
      setDeliveryAddress({ address: initialAddress });
    }

    if (dispatchType === 'takeout') {
      setCurbside({ isCurbside });
      if (curbsideInfo?.additionalInformation) {
        setCurbsideOutfitInfo({ curbsideOutfitInfo: localCurbsideOutfitInfo });
      }
    }

    if (timingOption === 'later') {
      setDispatchTime({ timestamp: selectedDateTime });
    } else {
      setDispatchTime({ timestamp: undefined });
    }

    onRequestClose();
  };

  useEffect(() => {
    const { dateError, timeError } = getDispatchTimeSelectorDetails({
      timingOption,
      dispatchTime: selectedDateTime,
      restaurant,
      dispatchType,
      t,
      idealDeliveryArea,
    });

    setHasErrors(Boolean(dateError || timeError));
  });

  const footer = (
    <ModalLayoutBasicFooter
      isMobile={isMobile}
      onOkClick={handleOkClick}
      onCancelClick={onRequestClose}
      isSaveBtnDisabled={hasErrors}
      fullWidthMobile
    />
  );

  const showCurbside = dispatchType === 'takeout' && curbsideInfo;

  return (
    <ModalLayoutSelection
      header={t('order_settings_modal_title')}
      footer={footer}
      onCloseClick={onRequestClose}
      data-hook="dispatch-settings-modal"
    >
      <div className={styles.wrapper} data-hook={dataHooks.dispatchSettingWrapper}>
        {supportedDispatchTypes.size > 1 && (
          <DispatchTypeSelector
            className={styles.selector}
            dispatchType={dispatchType}
            setDispatchType={(payload: SetDispatchTypePayload) => setType(payload.dispatchType)}
          />
        )}
        {dispatchType === 'takeout' && (
          <RestaurantTakeoutDetails
            address={restaurant.address}
            formattedAddressWithComment={formattedAddressWithComment}
          />
        )}
        <DispatchTimeSelector
          businessNotification={businessNotification}
          idealDeliveryArea={idealDeliveryArea}
          isMobile={isMobile}
          restaurant={restaurant}
          dispatchType={dispatchType}
          timingOption={timingOption}
          dispatchTime={selectedDateTime}
          onChange={({
            timingOption: newTimingOption,
            selectedDateTime: newSelectedDateTime,
          }: {
            timingOption: TimingOption;
            selectedDateTime?: number;
          }) => {
            setSelectedDateTime(newSelectedDateTime || 0);
            setTimingOption(newTimingOption);
          }}
          t={getCachedTranslationFunction(t)}
          isRTL={isRTL}
          isModal
        />
        {showCurbside && (
          <CurbsidePickup
            setCurbsideOutfitInfo={setLocalCurbsideOutfitInfo}
            curbsideOutfitInfo={localCurbsideOutfitInfo}
            isCurbsideOn={isCurbside}
            onCurbsideToggle={onCurbsideToggle}
            curbsideInfo={curbsideInfo}
          />
        )}
      </div>
    </ModalLayoutSelection>
  );
};

DispatchSettings.displayName = 'DispatchSettings';
export default translate()(DispatchSettings);
