import React from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import TextField from '../TextField';
import { CurbsideInfo } from '@wix/restaurants-client-logic';
import { translate } from 'yoshi-flow-editor-runtime';
import ToggleSwitch from '../ToggleSwitch';
import { TranslationFunction } from 'i18next';

export interface CurbsidePickupProps {
  curbsideOutfitInfo: string;
  setCurbsideOutfitInfo: (outfitInfo: string) => void;
  curbsideInfo: CurbsideInfo;
  isCurbsideOn: boolean;
  onCurbsideToggle: any;
  t: TranslationFunction;
}

const CurbsidePickup = ({
  isCurbsideOn,
  onCurbsideToggle,
  curbsideInfo,
  curbsideOutfitInfo,
  setCurbsideOutfitInfo,
  t,
}: CurbsidePickupProps) => {
  const instructionsText = curbsideInfo ? curbsideInfo?.instructions : '';
  const additionalInfoLabel = curbsideInfo?.additionalInformation;

  return (
    <div>
      <ToggleSwitch
        data-hook={dataHooks.dispatchSettingCurbsideToggle}
        checked={isCurbsideOn}
        onChange={() => {
          onCurbsideToggle(!isCurbsideOn);
        }}
      />
      <Text typography="p2-m">{t('mobile.delivery_modal_PickupOption_Curbside_Toggle')}</Text>
      {isCurbsideOn && (
        <div>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingCurbsideInstructions}>
            {instructionsText}
          </Text>
          {curbsideInfo.additionalInformationRequired && (
            <div>
              <Text typography="p2-s" data-hook={dataHooks.dispatchSettingCurbsideOutfitInfoLabel}>
                {additionalInfoLabel}
              </Text>
              <TextField
                data-hook={dataHooks.dispatchSettingCurbsideOutfitInfo}
                value={curbsideOutfitInfo}
                onChange={(e) => setCurbsideOutfitInfo(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default translate()(CurbsidePickup);
