import AddressSelectionModal from './AddressSelectionModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setDeliveryAddress,
  setDeliveryAddressFromForm,
  setSelectedAddressId,
} from '../../../../state/checkout/checkout.actions';
import { getDisplayableAddressError } from '../AddressInformation/AddressInformation.helper';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { saveAddressToServer } from '../../../../state/session/session.actions';
import {
  setAddressInputError,
  setErrorVisibility,
  setSelectedAddress,
} from '../../../../state/addressInformationForm/addressForm.actions';
import { convertMembersAddressToOloAddress } from '../../../../core/logic/addressLogic';

function mapStateToProps(state: AppState, ...ownProps: any) {
  const { priceComponents } = cartSummarySelector(state);

  return {
    addresses: state.session.savedAddresses.map((savedAddress) => {
      const address = convertMembersAddressToOloAddress(savedAddress);

      return {
        ...savedAddress,
        error: getDisplayableAddressError({
          address,
          restaurant: state.session.restaurant,
          dispatchTime: state.checkout.dispatch.time,
          totalOrderPrice: priceComponents.total,
          t: (str) => str,
        }),
      };
    }),
    defaultAddressId: state.session.defaultAddressId,
    restaurant: state.session.restaurant,
    dispatchTime: state.checkout.dispatch.time,
    totalOrderPrice: priceComponents.total,
    addressInputError: state.addressForm.addressInputError,
    selectedAddressOption: state.addressForm.selectedAddressOption,
    selectedAddressId: state.checkout.selectedAddressId,
    isLoadingAddressesFromServer: state.session.isLoadingAddressesFromServer,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDeliveryAddress,
      saveAddressToServer,
      setDeliveryAddressFromForm,
      setAddressInputError,
      setErrorVisibility,
      setSelectedAddress,
      setSelectedAddressId,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelectionModal);
